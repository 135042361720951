import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import agent from '../../app/api/agent';

export default function GetPDFBill() {
    const {orderid} = useParams<{orderid: string}>();

    function downloadPDFBill() {
        agent.Orders.getPDFBill(orderid).then(response => {
           
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response], 
                {type: 'application/pdf'});
        //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            console.log(file);
        //Open the URL on new Window
            window.open(fileURL);
        })
    }

    return (
        <Fragment>
            <Helmet>
                <title>Ihre Rechnung | kayomo GmbH</title>      
                <meta name="description" content="Ihre Rechnung" />
                <meta name="keywords" content="rechnung" />
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="noindex" />
            </Helmet>


        <h1>Ihre Rechnung</h1>
        <p>Bitte speichern Sie die Rechnung für Ihre Unterlagen ab.</p>
        <Button onClick={() => downloadPDFBill()}><FontAwesomeIcon icon={faDownload} style={{marginRight: '0.5em'}}/>Rechnung herunterladen</Button>
        </Fragment>
    )
}