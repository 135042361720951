import React, { Fragment } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function AdvertProducts() {

    return (
        <Fragment>
        <Table striped bordered hover>
            <thead>
                <tr>
                <th>Anzeigeseite</th>
                <th>Anzeigeart</th>
                <th style={{textAlign: 'center'}}>1 Monat (EUR)*</th>
                <th style={{textAlign: 'center'}}>3 Monate (EUR)*</th>
                <th style={{textAlign: 'center'}}>6 Monate (EUR)*</th>
                <th style={{textAlign: 'center'}}>12 Monate (EUR)*</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Homepage</td>
                <td>Banner</td>
                <td style={{textAlign: 'right'}}>60,00</td>
                <td style={{textAlign: 'right'}}>171,00</td>
                <td style={{textAlign: 'right'}}>324,00</td>
                <td style={{textAlign: 'right'}}>612,00</td>
                </tr>
                <tr>
                <td>Homepage</td>
                <td>Block</td>
                <td style={{textAlign: 'right'}}>30,00</td>
                <td style={{textAlign: 'right'}}>85,00</td>
                <td style={{textAlign: 'right'}}>162,00</td>
                <td style={{textAlign: 'right'}}>306,00</td>
                </tr>

                <tr>
                <td>Artikel</td>
                <td>Banner</td>
                <td style={{textAlign: 'right'}}>40,00</td>
                <td style={{textAlign: 'right'}}>114,00</td>
                <td style={{textAlign: 'right'}}>216,00</td>
                <td style={{textAlign: 'right'}}>408,00</td>
                </tr>
                <tr>
                <td>Artikel</td>
                <td>Block</td>
                <td style={{textAlign: 'right'}}>20,00</td>
                <td style={{textAlign: 'right'}}>57,00</td>
                <td style={{textAlign: 'right'}}>108,00</td>
                <td style={{textAlign: 'right'}}>204,00</td>
                </tr>

                <tr>
                <td>Artikelübersicht</td>
                <td>Banner</td>
                <td style={{textAlign: 'right'}}>30,00</td>
                <td style={{textAlign: 'right'}}>86,00</td>
                <td style={{textAlign: 'right'}}>162,00</td>
                <td style={{textAlign: 'right'}}>306,00</td>
                </tr>
                <tr>
                <td>Artikelübersicht</td>
                <td>Block</td>
                <td style={{textAlign: 'right'}}>15,00</td>
                <td style={{textAlign: 'right'}}>43,00</td>
                <td style={{textAlign: 'right'}}>81,00</td>
                <td style={{textAlign: 'right'}}>153,00</td>
                </tr>

                <tr>
                <td>Seite</td>
                <td>Banner</td>
                <td style={{textAlign: 'right'}}>20,00</td>
                <td style={{textAlign: 'right'}}>57,00</td>
                <td style={{textAlign: 'right'}}>108,00</td>
                <td style={{textAlign: 'right'}}>204,00</td>
                </tr>
                <tr>
                <td>Seite</td>
                <td>Block</td>
                <td style={{textAlign: 'right'}}>10,00</td>
                <td style={{textAlign: 'right'}}>28,00</td>
                <td style={{textAlign: 'right'}}>54,00</td>
                <td style={{textAlign: 'right'}}>102,00</td>
                </tr>
            </tbody>
        </Table>

    <p>* Alle Preise netto, zzgl. MwSt.</p>
    <hr />
 <p>Wenn Sie einen Werbeslot auf dieser Webseite buchen möchten, verwenden Sie bitte unser Kontaktformular.</p>
 <p><Button as={Link} to='/kontakt' variant='success'>Zum Kontaktformular</Button></p>
 <p>Benennen Sie bei Ihrer Anfrage bitte den gewüschten Slot, die Laufzeit und den von Ihnen gewünschten Starttermin der Werbung. Wir prüfen die Verfügbarkeiten und unterbreiten Ihnen ein unverbindliches Angebot.</p>
</Fragment>
    )
}