import React, { Fragment, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { IBlogPostShort } from '../../app/models/content/IBlogPostShort';

interface Props {
    articleCount: number;
}

export default function LastArticlesBlock ({articleCount}: Props) {
    const [blogposts, setBlogposts] = useState<IBlogPostShort[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        agent.Posts.lastPosts(articleCount).then(response => {
            // console.log(response);
            setBlogposts(response);
            setIsLoading(false);
        })
    }, [articleCount])

    if (isLoading) return <LoadingComponent content='Loading...' />

    return (
        <Fragment>
            <Card style={{marginBottom: '1em'}}>
                <Card.Header><strong>Letzte Artikel</strong></Card.Header>
                <Card.Body>
                    {/* <Card.Text> */}
                        <ul style={{listStylePosition: 'inside', paddingLeft: '0', fontSize: 'small'}}>
                        {blogposts.map((post: IBlogPostShort, i) => (
                            <li key={i}><Link to={`/artikel/${post.slug}`}>{post.title}</Link></li>
                        ))}
                        </ul>
                    {/* </Card.Text> */}
                </Card.Body>
                </Card>
        </Fragment>
    )
}