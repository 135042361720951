import { Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import agent from '../../app/api/agent';
import { IOrderPost } from '../../app/models/order/IOrderPost';
import { OrderPost } from '../../app/models/order/OrderPost';
import * as Yup from 'yup';
import ReactTagInput from '@pathofdev/react-tag-input';
import '../../../node_modules/@pathofdev/react-tag-input/build/index.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IOrderPostText, IOrderPostTextResult } from '../../app/models/order/IOrderPostText';

export default function OrderInfo() {

    const {orderid} = useParams<{orderid: string}>();
    const {postid} = useParams<{postid: string}>();

    const [post, setPost] = useState<OrderPost>(new OrderPost());
    const [tags, setTags] = useState([] as any);
    const [disableForm, setDisableForm] = useState(false);
    const [checktext, setCheckText] = useState<boolean>(false);
    const [textChecked, setTextChecked] = useState<boolean>(false);
    const [metaKeywords, setMetaKeywords] = useState([] as any);

    const [checkTextResult, setTextCheckResult] = useState<IOrderPostTextResult>({'wordCount': 0, 'linkCount': 0, 'isError': false, 'message': '', 'wordCountMin': 0, 'wordCountMax': 0, 'linkCountMax': 0});

    useEffect(() => {        
        console.log(`OrderPost: ${orderid} | ${postid}`);
        if (orderid && postid) {
            agent.OrderPosts.loadOrderPost(orderid, postid).then(response => {
                // console.log(response);
                setDisableForm(response.status === 0 ? false : true)
                setPost(response);
                setTags(response.tags);
                setMetaKeywords(response.metaKeywords)
            })
        } else {
            setPost(new OrderPost());
        }
    },[orderid, postid])

    function handleFormSubmit(input: IOrderPost) {
        input.tags = tags;
        input.metaKeywords = metaKeywords;

        console.log('Form abgesendet', input);
        let messageText = '';
        if (input.status === 1) {
            messageText = 'Der Artikel wurde zur Veröffentlichung übermittelt'
        } else {
            messageText = 'Artikel gespeichert'
        }

        try {
            agent.OrderPosts.updateOrderPost(input).then(response => {
                // console.log(response);
                toast.success(messageText, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    });
            })
        } catch (error) {
            throw error;
        }
    }

    function checkText(content: string) {
        setCheckText(true);
        // console.log('CHECK TEXT', content);

        var textToCheck = {
            text: content,
            orderId: orderid
        } as IOrderPostText;

        setTextChecked(true);
        
        try {
            agent.OrderPosts.checkOrderPostText(textToCheck).then(response => {
                // console.log(response);

                if (response.isError) {
                    setTextCheckResult(response);
                    toast.error(`Fehler: Der Text erfüllt nicht die Anforderungen - Siehe Hinweis am Ende der Seite`, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        });
                } else {
                    setTextCheckResult(response);
                    toast.success(`Der Text erfüllt die Anforderungen - Super!`, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        draggable: true,
                        progress: undefined,
                        });
                }
            })
        } catch (error) {
            // setCheckText(false);
            throw error;
        }

        setCheckText(false);
    }

    const validationSchema = Yup.object({
        title: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .min(20, 'Mindestens 20 Zeichen lang')
            .max(120),
        slug: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .matches(/^[a-z-]+$/, 'Nur Buchstaben (ohne Umlaute) und "-"')
            .max(80),
        abstract: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .min(100, 'Mindestens 200 Zeichen lang')
            .max(300),
        content: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .min(100, 'Mindestens 100 Zeichen lang'),
        metaDescription: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .min(50, 'Mindestens 50 Zeichen lang')
            .max(160),
        comment: Yup.string()        
            .max(300),
        author: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .min(5, 'Mindestens 5 Zeichen lang')
            .max(50),
    })

    return (
        <Fragment>
        <h1>Eingabe Artikelinformationen</h1>
        <Alert variant='warning'>
           Auf Wunsch vieler Kunden haben wir die Textgröße (Inhalt) angepasst von 450-500 auf 500-1.500 Wörter
        </Alert>
        {post.status === 0 &&
            <p>Bitte hinterlegen Sie hier die Informationen für Ihren Backlinktext.</p>
        }
        {post.status === 1 &&
            <p>Der Artikel wurde von Ihnen freigegeben und kann nicht mehr geändert werden.</p>
        }
        <hr />

        <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={post}
                onSubmit={values => handleFormSubmit(values)}>
                {({handleSubmit,
                    handleChange,
                    values,
                    isSubmitting,
                    isValid,
                    dirty,
                    errors,}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <fieldset disabled={disableForm}>
                    <Form.Group controlId="title">
                        <Form.Label>Titel (die spätere H1-Überschrift)</Form.Label>
                        <Form.Control type="text" name="title" placeholder="Titel" onChange={handleChange} isInvalid={!!errors.title} value={values.title} />
                        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="slug">
                        <Form.Label>Slug (das Url-Fragment unter dem Ihr Artikel erscheinen soll)</Form.Label>
                        <Form.Control type="text" name="slug" placeholder="Slug" onChange={handleChange} isInvalid={!!errors.slug} value={values.slug} />
                        <Form.Control.Feedback type="invalid">{errors.slug}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="abstract">
                        <Form.Label>Zusammenfassung (erscheint in der Artikelübersicht, reiner Text)</Form.Label>
                        <Form.Control as="textarea" name="abstract" rows={4} onChange={handleChange} isInvalid={!!errors.abstract} value={values.abstract}/>
                    <Form.Control.Feedback type="invalid">{errors.abstract}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="content">
                        <Form.Label>Inhalt (der Text des Artikels, kann HTML für Absätze, Überschriften, etc. enthalten, 500-1.500 Wörter)</Form.Label>
                        <Form.Control as="textarea" name="content" rows={10} onChange={handleChange} isInvalid={!!errors.content} value={values.content}/>
                    <Form.Control.Feedback type="invalid">{errors.content}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="metaDescription">
                        <Form.Label>Meta Beschreibung (erscheint im HEAD-Bereich der Webseite, reiner Text)</Form.Label>
                        <Form.Control as="textarea" name="metaDescription" rows={2} onChange={handleChange} isInvalid={!!errors.metaDescription} value={values.metaDescription}/>
                    <Form.Control.Feedback type="invalid">{errors.metaDescription}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="metaKeywords">
                        <Form.Label>Meta Keywords (erscheinen im HEAD-Bereich der Webseite, max. 5 Stück - Eingabe und ENTER drücken für jedes Keyword)</Form.Label>
                        <ReactTagInput 
                            readOnly={disableForm}
                            tags={metaKeywords}   
                            maxTags={5}                         
                            placeholder="Eingabe und ENTER drücken"
                            onChange={(newTags) => setMetaKeywords(newTags)}
                        />
                        <Form.Control.Feedback type="invalid">{errors.metaKeywords}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="tags">
                        <Form.Label>Tags (Stichworte, die zum Artikel angezeigt werden, max. 5 Stück - Eingabe und ENTER drücken für jeden Tag)</Form.Label>
                        <ReactTagInput
                            readOnly={disableForm}
                            tags={tags}            
                            maxTags={5}                
                            placeholder="Eingabe und ENTER drücken"
                            onChange={(newTags) => setTags(newTags)}
                        />
                        <Form.Control.Feedback type="invalid">{errors.tags}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="author">
                        <Form.Label>Autor (Name des Autors, der zum Artikel angezeigt werden soll)</Form.Label>
                        <Form.Control type="text" name="author" placeholder="Author" onChange={handleChange} isInvalid={!!errors.author} value={values.author} />
                        <Form.Control.Feedback type="invalid">{errors.author}</Form.Control.Feedback>
                    </Form.Group>


                    <hr />
                    <Form.Group controlId="comment">
                        <Form.Label>Kommentar (hinterlegen Sie bei Bedarf hier einen Kommentar)</Form.Label>
                        <Form.Control as="textarea" name="comment" rows={2} onChange={handleChange} isInvalid={!!errors.comment} value={values.comment}/>
                    <Form.Control.Feedback type="invalid">{errors.comment}</Form.Control.Feedback>
                    </Form.Group>

                    
                    {post.status === 0 && !disableForm &&
                    <Fragment>
                    <hr />
                    <Form.Row>
                        <Col>
                            <Button
                                style={{marginRight: '0.5em'}}
                                disabled={!isValid || checktext}
                                variant="success"
                                type="submit">
                                    Speichern
                            </Button>
                            <Button
                                disabled={!isValid || checktext}
                                variant="primary"
                                onClick={() => checkText(values.content)}
                                >
                                    Artikel prüfen
                            </Button>
                        </Col>
                        <Col style={{textAlign: 'right'}}>
                        
                        <Button
                                disabled={checkTextResult.isError || !textChecked || !isValid}
                                variant="danger"
                                onClick={() => {
                                    setDisableForm(true);
                                    values.status = 1;
                                    handleFormSubmit(values);
                                }}
                                >
                                    Freigeben
                            </Button>
                        </Col>
                    </Form.Row>
                    </Fragment>
                    }
                </fieldset>
                </Form>
                )}
            </Formik>

            {checkTextResult?.isError &&
                <Alert variant='danger' style={{marginTop: '1em'}}>
                    <ul>
                        {((checkTextResult.wordCount < checkTextResult.wordCountMin) || (checkTextResult.wordCount > checkTextResult.wordCountMax)) &&
                            <li>Wortanzahl: {checkTextResult.wordCount} (mindestens {checkTextResult.wordCountMin}, maximal {checkTextResult.wordCountMax} Wörter)</li>
                        }
                        <li>Linkanzahl: {checkTextResult.linkCount} (maximal {checkTextResult.linkCountMax} Links im Text)</li>
                    </ul>
                </Alert>
            }

            {!checkTextResult?.isError && textChecked &&
                <Alert variant='success' style={{marginTop: '1em'}}>
                    <ul>
                        <li>Wortanzahl: {checkTextResult.wordCount} (mindestens {checkTextResult.wordCountMin}, maximal {checkTextResult.wordCountMax} Wörter)</li>
                        <li>Linkanzahl: {checkTextResult.linkCount} (maximal {checkTextResult.linkCountMax} Links im Text)</li>
                    </ul>
                </Alert>
            }

            <ToastContainer
                pauseOnHover={false}
            />       
        </Fragment>
        
    )
}
