import React, { Fragment, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';

export default function NewsletterVerify() {

    const {id} = useParams<{id: string}>();

    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(0);

    useEffect(() => {        
        // console.log(`Newsletter Verification: ${id}`);
        setIsLoading(true);
        agent.Forms.verifyNewsletter(id).then(response => {
            console.log(response);
            setIsLoading(false);
            setSuccess(response)
        })
        }, [id])

    if (isLoading) return <LoadingComponent content='Loading...' />

    return (
        <Fragment>
        <h1>Newsletter-Bestätigung</h1>
        {success === 0 && 
            <Alert variant='warning'>
                Es konnte keine Eintragung zur Bestätigung gefunden werden.
            </Alert>
        }

        {success === 1 &&
            <Alert variant='success'>
                Vielen Dank, dass Sie unserem Newsletter beigetreten sind.
            </Alert>
        }

        {success === 2 &&
            <Alert variant='danger'>
                Es ist leider ein Fehler aufgetreten.
            </Alert>
        }

        {success === 10 &&
            <Alert variant='dark'>
                Sie haben die Teilnahme an unserem Newsletter bereits bestätigt.
            </Alert>
        }
      
        </Fragment>
    )
}