export class OrderPost {
    id:number;
    title: string;
    abstract: string;
    content: string;
    author: string;
    tags: string[];
    slug: string;
    metaDescription: string;
    metaKeywords: string[];
    comment: string;
    status: number;
    // postsWithBio: boolean;
    // orderBio: Bio;

    constructor() {
        this.id = 0;
        this.title = '';
        this.abstract = '';
        this.content = '';
        this.author = '';
        this.tags = [];
        this.slug = '';
        this.metaDescription = '';
        this.metaKeywords = [];
        this.comment = '';
        this.status = 0;
        // this.postsWithBio = false;
        // this.orderBio = new Bio();
    }
}