import { faBalanceScale, faReceipt, faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardGroup } from 'react-bootstrap';
import { useMediaQuery } from '../../app/common/useMediaQuery';

interface Props {
    actualStep: number;
}

export default observer(function CheckOutProgress({actualStep}: Props) {

    const [width] = useMediaQuery();
    
    // const { checkoutStore } = useStore();
    const [step, setStep] = useState(actualStep);

    useEffect(() => {        
        setStep(actualStep);

        // console.log('STEP', step);
    }, [actualStep, step])

    return (
        <Fragment>

        {width < 770 &&
            <hr />
        }
        
        {width >770 &&
        <CardGroup style={{marginBottom: '1em'}}>
             <Card
             bg={step === 1 ? 'success' : 'light'}
             text={step === 1 ? 'white' : 'dark'}
             >
                <Card.Header>Schritt 1</Card.Header>
                <Card.Body><Card.Title><FontAwesomeIcon icon={faUser} style={{marginRight: '0.5em'}}/>Kundendaten</Card.Title></Card.Body>
            </Card>
            <Card
             bg={step === 2 ? 'success' : 'light'}
             text={step === 2 ? 'white' : 'dark'}
             >
                <Card.Header>Schritt 2</Card.Header>
                <Card.Body><Card.Title><FontAwesomeIcon icon={faBalanceScale} style={{marginRight: '0.5em'}}/>Rechtliches</Card.Title></Card.Body>
            </Card>
            <Card
             bg={step === 3 ? 'success' : 'light'}
             text={step === 3 ? 'white' : 'dark'}
             >
                <Card.Header>Schritt 3</Card.Header>
                <Card.Body><Card.Title><FontAwesomeIcon icon={faReceipt} style={{marginRight: '0.5em'}}/>Zusammenfassung</Card.Title></Card.Body>
            </Card>
            <Card
             bg={step === 4 ? 'success' : 'light'}
             text={step === 4 ? 'white' : 'dark'}
             >
                <Card.Header>Schritt 4</Card.Header>
                <Card.Body><Card.Title><FontAwesomeIcon icon={faShoppingCart} style={{marginRight: '0.5em'}}/>Bezahlen</Card.Title></Card.Body>
            </Card>
            {/* <Card
             bg={step === 5 ? 'success' : 'light'}
             text={step === 5 ? 'white' : 'dark'}
             >
                <Card.Header>Schritt 5</Card.Header>
                <Card.Body><Card.Title>Bezahlen</Card.Title></Card.Body>
            </Card> */}
        </CardGroup>
        }
        </Fragment>
    )
})