import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Bio } from '../../app/models/order/Bio';
import { IBio } from '../../app/models/order/IBio';
import agent from '../../app/api/agent';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ICountry } from '../../app/models/order/ICountry';
import ReactTagInput from '@pathofdev/react-tag-input';

export default function OrderBio() {

    const {orderid} = useParams<{orderid: string}>();
    const {bioid} = useParams<{bioid: string}>();

    const [bio, setBio] = useState<Bio>(new Bio());
    const [tags, setTags] = useState([] as any);
    const [countries, setCountries] = useState<ICountry[]>();

    useEffect(() => {        
        console.log(`OrderBio: ${orderid} | ${bioid}`);
        if (orderid && bioid) {
            agent.OrderPosts.loadOrderBio(orderid, bioid).then(response => {
                console.log(response);
                // setDisableForm(response.status === 0 ? false : true)
                setBio(response);
                setTags(response.bioTags);
                // setMetaKeywords(response.metaKeywords)
            })
        } else {
            // setPost(new OrderPost());
        }
    },[orderid, bioid])

    useEffect(() => {        
        agent.Orders.loadCountries().then(response => {
            // console.log(response);
            setCountries(response);
        })
    }, [])

    function handleFormSubmit(input: IBio) {
        console.log('Bio-Form abgesendet', input);
        input.bioTags = tags;
        try {
            agent.OrderPosts.updateOrderBio(input).then(response => {
                console.log(response);
                toast.success('Unternehmensinformationen aktualisiert', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
                    });
            })
        } catch (error) {
            throw error;
        }
    }

    const validationSchema = Yup.object({
        company: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .min(3, 'Mindestens 20 Zeichen lang')
            .max(100, 'Maximal 100 Zeichen'),
        url: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .matches(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi, 'Bitte geben Sie eine gültige Url ein')
            .max(30, 'Maximal 30 Zeichen'),
        urlText: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .min(5, 'Mindestens 5 Zeichen lang')
            .max(30, 'Maximal 30 Zeichen'),
        content: Yup.string()        
            .required('Bitte geben Sie einen Wert ein')
            .min(100, 'Mindestens 100 Zeichen lang')
            .max(300, 'Maximal 300 Zeichen'),
        phone: Yup.string()
            .min(5, 'Mindestens 5 Zeichen lang')           
            .max(20, 'Maximal 20 Zeichen'),
        contactEmail: Yup.string()        
            .email('Bitte geben Sie eine gültige Email-Adresse ein')
            .max(30, 'Maximal 30 Zeichen'),
        street: Yup.string()
            .min(5, 'Mindestens 5 Zeichen lang')
            .max(50, 'Maximal 50 Zeichen'),
        postal: Yup.string()    
            .min(5, 'Mindestens 5 Zeichen lang')
            .max(10, 'Maximal 10 Zeichen'),
        city: Yup.string()   
            .min(3, 'Mindestens 3 Zeichen lang')     
            .max(30, 'Maximal 30 Zeichen'),
    })

    return (
        <Fragment>
        <h1>Eingabe Unternehmensinformationen</h1>
        <p>Bitte hinterlegen Sie hier Ihre Unternehmensinformationen zu Ihrer Bestellung.</p>
        <p>Diese Information wird unter einem Artikel bzw. in einer Unternehmenssuche auf der Webseite angezeigt.</p>
        <hr />
        <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={bio}
                onSubmit={values => handleFormSubmit(values)}>
                {({handleSubmit,
                    handleChange,
                    values,
                    isSubmitting,
                    isValid,
                    dirty,
                    errors,}) => (
        <Form noValidate onSubmit={handleSubmit}>
        <Form.Row>
            <Col>
                <Form.Group controlId="company">
                    <Form.Label>Firmenname (*)</Form.Label>
                    <Form.Control type="text" name="company" placeholder="Firmenname" onChange={handleChange} isInvalid={!!errors.company} value={values.company} />
                    <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="url">
                    <Form.Label>Url der Webseite (*)</Form.Label>
                    <Form.Control type="text" name="url" placeholder="Url der Webseite" onChange={handleChange} isInvalid={!!errors.url} value={values.url} />
                    <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="urlText">
                    <Form.Label>Url-Anzeigetext (*)</Form.Label>
                    <Form.Control type="text" name="urlText" placeholder="Anzeigetext der Url" onChange={handleChange} isInvalid={!!errors.urlText} value={values.urlText} />
                    <Form.Control.Feedback type="invalid">{errors.urlText}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Form.Row>

        <Form.Group controlId="content">
            <Form.Label>Kurzbeschreibung zum Unternehmen (*, reiner Text, kein HTML)</Form.Label>
            <Form.Control as="textarea" name="content" rows={5} onChange={handleChange} isInvalid={!!errors.content} value={values.content}/>
            <Form.Control.Feedback type="invalid">{errors.content}</Form.Control.Feedback>
        </Form.Group>

        <Form.Row>
            <Col>
                <Form.Group controlId="phone">
                    <Form.Label>Telefonnummer</Form.Label>
                    <Form.Control type="text" name="phone" placeholder="Telefonnummer" onChange={handleChange} isInvalid={!!errors.phone} value={values.phone} />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="contactEmail">
                    <Form.Label>Kontakt-Email</Form.Label>
                    <Form.Control type="text" name="contactEmail" placeholder="Emailadresse" onChange={handleChange} isInvalid={!!errors.contactEmail} value={values.contactEmail} />
                    <Form.Control.Feedback type="invalid">{errors.contactEmail}</Form.Control.Feedback>
                </Form.Group>
            </Col>                        
        </Form.Row>

        <Form.Row>
            <Col md={6}>
                <Form.Group controlId="street">
                    <Form.Label>Straße</Form.Label>
                    <Form.Control type="text" name="street" placeholder="Straße" onChange={handleChange} isInvalid={!!errors.street} value={values.street} />
                    <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Form.Row>
        <Form.Row>
            <Col>
                <Form.Group controlId="postal">
                    <Form.Label>Postleitzahl</Form.Label>
                    <Form.Control type="text" name="postal" placeholder="Postleitzahl" onChange={handleChange} isInvalid={!!errors.postal} value={values.postal} />
                    <Form.Control.Feedback type="invalid">{errors.postal}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="city">
                    <Form.Label>Stadt</Form.Label>
                    <Form.Control type="text" name="city" placeholder="Stadt" onChange={handleChange} isInvalid={!!errors.city} value={values.city} />
                    <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="country">
                    <Form.Label>Land</Form.Label>
                        <Form.Control as="select" name="country" onChange={handleChange} value={values.country.id}>
                        {countries?.map((country: ICountry, i) => (
                            <option key={i} value={country.id}>{country.name}</option>
                        ))}
                        </Form.Control>
                </Form.Group>
            </Col>
        </Form.Row>

        <Form.Group controlId="tags">
            <Form.Label>Tags (Stichworte, unter den Ihr Unternehmen gefunden werden soll, max. 5 Stück)</Form.Label>
            <ReactTagInput
                // readOnly={disableForm}
                tags={tags}            
                maxTags={5}                           
                placeholder="Eingabe und ENTER drücken"
                onChange={(newTags) => setTags(newTags)}
            />
            {/* <Form.Control.Feedback type="invalid">{errors.tags}</Form.Control.Feedback> */}
        </Form.Group>

        <hr />
        <Form.Row>
        <Button
                disabled={isSubmitting || !dirty || !isValid}
                variant="success"
                type="submit">
                    Speichern
                </Button>
        </Form.Row>

        </Form>
         )}
        </Formik>

        <ToastContainer
                pauseOnHover={false}
        />   

        </Fragment>
    )
}