import { useMatomo } from '@datapunt/matomo-tracker-react';
import { faBan, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import { useStore } from '../../app/stores/store';

interface Props {
    updateSearch: (search: string) => void;
}

export default observer(function SearchBox({updateSearch}: Props) {

    const { trackSiteSearch } = useMatomo();
    const { blogStore } = useStore();
    const { searchParamValue } = blogStore;

    const [touched, setTouched] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {  
        if (searchParamValue !== '') {
            setSearchValue(searchParamValue);
            setTouched(true);
        }
    }, [searchParamValue])

    function keyPress(e: any){
        if(e.keyCode === 13){
        // console.log('value', e.target.value);
           updateSearch(e.target.value)

           if (e.target.value !== '') {
            trackSiteSearch({
                keyword: e.target.value
                })
           }
        }
     }

     function inputChange(e: any) {
        setSearchValue(e.target.value);

        if (e.target.value !== '') {
            setTouched(true);
        } else {
            setTouched(false);
        }
     }

     function resetInput() {
         setSearchValue('');
         setTouched(false);
         updateSearch('');
     }

    return (
        <Fragment>
            <Card style={{marginBottom: '1em'}}
            bg={'light'}
            text={'dark'}
            >
                <Card.Header><strong>Suche</strong></Card.Header>
                <Card.Body>
                <InputGroup>
                <Form.Control
                    type="text"
                    placeholder="Suchbegriff"
                    aria-describedby="inputGroupPrepend"
                    value={searchValue}
                    onKeyDown={keyPress}
                    onChange={inputChange}
                    maxLength={30}
                    // required
                    />
                    <InputGroup.Append>
                    {!touched &&
                        <Button variant="success"><FontAwesomeIcon icon={faSearch} /></Button>
                    }

                    {touched &&
                        <Button variant="danger" onClick={() => resetInput()}><FontAwesomeIcon icon={faBan} /></Button>
                    }
                    </InputGroup.Append>
                    
                </InputGroup>
                </Card.Body>
            </Card>
        </Fragment>
    )
})
