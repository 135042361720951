import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useMediaQuery } from '../../app/common/useMediaQuery';
import { useStore } from '../../app/stores/store';
import MoneyOrderPayment from '../payment/MoneyOrder';
import PayPalPayment from '../payment/PayPalPayment';

interface Props {
    stepAction: (step: number) => void;
}

export default function CheckOutPayment({stepAction}: Props) {

    const [width] = useMediaQuery();
    
    const { checkoutStore } = useStore();
    const { order } = checkoutStore;

    const [paymentMethod, setPaymentMethod] = useState('');

    const [paymentProcess, setPaymentProcess] = useState(false);

    function handleButtons(enabled: boolean) {
        setPaymentProcess(enabled);
    }

    return (
        <Fragment>
            <h2>Bezahlen</h2>
            <hr />

            <Row>
            <Col>
                <Row>
                    <Col>
                    <Button block
                        style={{marginBottom: '1em'}}
                        size="lg"
                        variant={paymentMethod === 'moneyorder' ? 'primary' : 'secondary'}
                        disabled={paymentProcess}
                        onClick={() => setPaymentMethod('moneyorder')}>
                            <FontAwesomeIcon icon={faMoneyCheck} style={{marginRight: '0.5em'}}/>
                            Überweisung</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Button block
                        size="lg"
                        variant={paymentMethod === 'paypal' ? 'primary' : 'secondary'}
                        disabled={paymentProcess || order.total.total === 0}
                        onClick={() => setPaymentMethod('paypal')}>
                            <FontAwesomeIcon icon={faPaypal} style={{marginRight: '0.5em'}}/>
                            PayPal</Button>
                    </Col>
                </Row>

                <hr />
                <span style={{fontWeight: 'bold'}}>Gesamtbetrag: <NumberFormat value={order.total.total} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale /> EUR</span>
            </Col>     
            
            {width > 770 && 
                <Col>
                {paymentMethod === 'moneyorder' &&
                    <MoneyOrderPayment stepAction={stepAction}  />
                }
                {paymentMethod === 'paypal' &&
                    <PayPalPayment orderData={checkoutStore.order} processAction={handleButtons} />
                }
                </Col>       
            }
            </Row>

            {width < 770 &&
                <Row style={{marginTop: '1em'}}>
                    <Col>
                    {paymentMethod === 'moneyorder' &&
                        <MoneyOrderPayment stepAction={stepAction}  />
                    }
                    {paymentMethod === 'paypal' &&
                        <PayPalPayment orderData={checkoutStore.order} processAction={handleButtons} />
                    }
                    </Col>  
                </Row>
            }

        </Fragment>
    )
}