import { makeAutoObservable } from "mobx";
import { IOrderItem } from "../models/order/IOrderItem";
import { ITaxInfo } from "../models/order/ITaxInfo";
import { OrderObject } from "../models/order/OrderObject";
import { IPortal } from "../models/portals/IPortal";

export default class CheckoutStore {    

    step: number = 1;
    order = new OrderObject();
    

    constructor() {
        makeAutoObservable(this)
    }

    setOrderItem(item: IOrderItem) {
        this.order.basket = [];
        this.order.basket.push(item);
        this.calculateTotal();
        // console.log(this.order.basket);
    }

    calculateTotal = () => {
        this.order.total.sum = 0;
        this.order.total.tax = 0;
        this.order.total.total = 0;
        
        this.order.basket.map(item => {
            this.order.total.sum += item.price;            
            this.order.total.tax += this.order.taxInfo.taxEP * item.price / 100;
            return this.order.total.total = this.order.total.sum + this.order.total.tax;
        })        
    }

    resetOrderStore() {
        this.order = new OrderObject();
        this.step = 1;
    }

    updateStep(step: number) {
        this.step = step;
    }

    setSiteId(portal: IPortal) {
        this.order.portal = portal;
    }

    setGW(checked: boolean) {
        this.order.acceptedBusiness = checked;
    }

    setAGB(checked: boolean) {
        this.order.acceptedTerms = checked;
    }

    setContentCheck(checked: boolean) {
        this.order.acceptedContent = checked;
    }

    setOrderRef(orderRef: string) {
        this.order.orderRef = orderRef;
    }

    setVatValid(valid: boolean) {
        this.order.customer.vatValid = valid;
    }

    setTaxInfo(taxInfo: ITaxInfo) {
        this.order.taxInfo = taxInfo;
    }

    get acceptedAllTerms () {
        var result = this.order.acceptedTerms && this.order.acceptedBusiness && this.order.acceptedContent;
        return result;
    }

}