import { observer } from 'mobx-react-lite';
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { Badge, Card, ListGroup } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { ITag } from '../../app/models/content/ITag';
import { useStore } from '../../app/stores/store';

interface Props {
    updateSearch: (search: string) => void;
}

export default observer(function TagSummaryBlock({updateSearch}: Props) {

    const { blogStore } = useStore();
    const { searchTagValue } = blogStore;

    const [isLoading, setIsLoading] = useState(true);
    const [tags, setTags] = useState<ITag[]>([]);
    const [tagSelected, setTagSelected] = useState(false);

    useEffect(() => {  
        if (searchTagValue !== '') {
            setTagSelected(true);
        }
    }, [searchTagValue])

    useEffect(() => {
        setIsLoading(true);
        agent.Posts.loadTagSummary(5).then(response => {
            // console.log(response);
            setTags(response);
            setIsLoading(false);
        })
    }, [])

    function selectedTag(tag: ITag | null) {
        // console.log(tag);
        if (tag) {
            updateSearch(tag.name);
            setTagSelected(true);
        } else {
            updateSearch('');
            setTagSelected(false);
        }
    }

    if (isLoading) return <LoadingComponent content='Loading...' />

    return (
        <Fragment>
            <Card style={{marginBottom: '1em'}}>
                <Card.Header><strong>Beliebte Tags</strong></Card.Header>
                <Card.Body style={{padding: '0'}}>
                <ListGroup variant={'flush'} className='small'>
                    {tagSelected && 
                        <ListGroup.Item key={'default'}
                        className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
                        onClick={() => selectedTag(null)} >Auswahl zurücksetzen <Badge pill variant="danger">X</Badge></ListGroup.Item>
                    }

                    {tags.map((tag: ITag, i) => (
                        <ListGroup.Item key={i}
                        className='list-group-item list-group-item-action d-flex justify-content-between align-items-center'
                        onClick={() => selectedTag(tag)} >{tag.name} <Badge pill variant="info">{tag.count}</Badge></ListGroup.Item>
                    )
                    )}
                   
                </ListGroup>
                </Card.Body>
                </Card>
        </Fragment>
    )
})