import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    return (
        <Fragment>
            <Helmet>
                <title>Linktausch und Marketing Portal | kayomo GmbH</title>      
                <meta name="description" content="Linktausch Plattform mit qualitativ hochwertigem Content zur Reichweitenerhöhung Ihrer Webseiten" />
                <meta name="keywords" content="suchmaschinenoptimierung, online marketing, mehr umsatz, mehr kunden" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <Row>
            <Col md={9}>

            {/* <AdvertsBlock advertType={1} advertCount={1} /> */}

            <AdvertOwnBlock
                title='Erfolgreich sein im Internet'
                content='Schwierige Zeiten erfordern schell wirkende Maßnahmen, um Ihr Online-Geschäft zu stärken oder gar zu retten. Informieren Sie sich jetzt, um von unserer über 12-jährigen Erfahrung in diesem Bereich zu profitieren.'
                image='/banners/mehr-umsatz.png'
                imageTarget='/erfolgreich-im-internet'
                targets={[
                    { linkText: 'Erfolgreich sein im Internet', linkTarget: '/erfolgreich-im-internet' },
                    { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Suchmaschinenoptimierung', linkTarget: '/suchmaschinenoptimierung' },
                    { linkText: 'Online Marketing', linkTarget: '/ihre-werbung' },
                ]}
            />

            <AdvertsBlock advertCount={1} advertType={1} />

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <NewsletterSubscribe />

                <BacklinkProductBlock />

                <AdvertsBlock advertCount={1} advertType={2} />
            </Col>
            </Row>
        </Fragment>
    )
}