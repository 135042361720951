import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { IPage } from '../../app/models/content/IPage';
import AdvertProducts from './AdvertProducts';

export default function AdvertInfo() {

    const [page, setPage] = useState<IPage>();

    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const { enableLinkTracking, trackPageView } = useMatomo();

    enableLinkTracking();

    useEffect(() => {    
        setIsLoading(true);
        agent.Pages.loadPage('ihre-werbung').then(response => {
            setPage(response);

            setIsLoading(false);
            
            trackPageView({
                documentTitle: page?.title
            });
        })

    }, [trackPageView, page?.title])

    function contentClickHandler(e: any)  {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        
        if (targetLink.dataset.target !== "ext") {
            e.preventDefault();
            history.push(targetLink.pathname);
            
        } else {
            e.preventDefault();
            window.open(
                targetLink.href,
                '_blank'
              );
        }
      };

    if (isLoading) return <LoadingComponent content='Loading...' />

    return (
        <Fragment>
            <Helmet>
                <title>{page?.title + ' | ' + process.env.REACT_APP_PORTAL_NAME}</title>
                <meta name="description" content={page?.metaDescription} />
                <meta name="keywords" content={page?.metaKeywords} />         
                <link rel="canonical" href={window.location.href} />
                {page?.noIndex &&
                    <meta name="robots" content="noindex" />
                }
            </Helmet>

                {/* <Row> */}
                    <h1>{page?.title}</h1>
                    <hr />
                    <div
                    onClick={contentClickHandler}
                    dangerouslySetInnerHTML={{
                        __html: page != null ? page.content : ''
                    }}></div>

                <AdvertProducts />
                {/* </Row> */}
        </Fragment>
    )
}