import { ICountry } from "./ICountry";

export class Bio {
    id: number;
    company: string;
    url: string;
    urlText: string;
    content: string;
    phone: string;
    contactEmail: string;
    street: string;
    postal: string;
    city: string;
    country: ICountry;
    bioTags: string[];

    constructor() {
        this.id = 0;
        this.company = '';
        this.url = '';
        this.urlText = '';
        this.content = '';
        this.phone = '';
        this.contactEmail = '';
        this.street = '';
        this.postal = '';
        this.city = '';
        this.country = {} as ICountry;
        this.bioTags = []; 
    }
}