import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import agent from "../../app/api/agent";
import {Helmet} from "react-helmet";
import { IPage } from "../../app/models/content/IPage";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import LoadingComponent from "../../app/layout/LoadingComponent";

export default observer(function BacklinksOffer () {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const history = useHistory();
    const [page, setPage] = useState<IPage>();

    const [isLoading, setIsLoading] = useState(true);

    const slug = 'ihre-backlinks';

    useEffect(() => {    
        setIsLoading(true);
        agent.Pages.loadPage(slug).then(response => {
            setPage(response);

            setIsLoading(false);

            trackPageView({
                documentTitle: page?.title
            });
        })
    }, [trackPageView, page?.title])

    function contentClickHandler(e: any)  {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        
        if (targetLink.dataset.target !== "ext") {
            e.preventDefault();
            history.push(targetLink.pathname);
            
        } else {
            window.location.href = targetLink.href;
        }
      };

    if (isLoading) return <LoadingComponent content='Loading...' />

    return (
        <Fragment>
            <Helmet>
                <title>{page?.title + ' | kayomo GmbH'}</title>
                <meta name="description" content={page?.metaDescription} />
                <meta name="keywords" content={page?.metaKeywords} />         
                <link rel="canonical" href={window.location.href} />
                {page?.noIndex &&
                    <meta name="robots" content="noindex" />
                }
            </Helmet>
            
            {page?.fullSize && (
                <Row>
                <Col md={12}>
                    <h1>{page?.title}</h1>
                    <hr />
                    <div
                    onClick={contentClickHandler}
                    dangerouslySetInnerHTML={{
                        __html: page != null ? page.content : ''
                    }}></div>

                    <div style={{textAlign: 'center'}}>
                    {/* <Button size="lg" variant="success" as={Link} to="/ihre-backlinks/produktauswahl"> */}
                    <Button size="lg" variant="success" as={Link} to="/ihre-backlinks/portalauswahl">
                    <FontAwesomeIcon icon={faShoppingCart} style={{marginRight: '0.5em'}}/>Zu unseren Backlink-Paketen
                    </Button>
                    </div>

                </Col>
                </Row>
            )}

            {!page?.fullSize && (
                <Row>
                <Col md={9}>
                    <h1>{page?.title}</h1>
                    <hr />
                    <div
                    dangerouslySetInnerHTML={{
                        __html: page != null ? page.content : ''
                    }}></div>

                    <div style={{textAlign: 'center'}}>
                    {/* <Button size="lg" variant="success" as={Link} to="/ihre-backlinks/produktauswahl"> */}
                    <Button size="lg" variant="success" as={Link} to="/ihre-backlinks/portalauswahl">
                    <FontAwesomeIcon icon={faShoppingCart} style={{marginRight: '0.5em'}}/>Zu unseren Backlink-Paketen
                    </Button>
                    </div>
                </Col>
                <Col md={3}>
                    SIDEBAR_POSTS
                </Col>
                </Row>
            )}

        </Fragment>
    )
})

