import { useMatomo } from "@datapunt/matomo-tracker-react";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite"
import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import agent from "../../app/api/agent";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { IPortal } from "../../app/models/portals/IPortal";
import { useStore } from "../../app/stores/store";

export default observer(function BacklinkPortalSelection() {

    const { enableLinkTracking, trackPageView, trackEvent } = useMatomo();
    enableLinkTracking();

    const history = useHistory();
    const { checkoutStore } = useStore();

    const [portals, setPortals] = useState<IPortal[]>([]);
    const [portalId, setPortalId] = useState('');
    const [portal, setPortal] = useState<IPortal>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {        
        trackPageView({
            documentTitle: 'Backlink - Portalauswahl'
        });

        setIsLoading(true);
        agent.Orders.loadPortals().then(response => {
            // console.log(response);
            setPortals(response);

            // hier muss das erste IsEnabled Portal ausgewählt werden.
            var enabledPortal = response.find(portal => portal.isEnabled);
            setPortal(enabledPortal);
            setPortalId(enabledPortal?.siteId || '0')

            //setPortal(response[0]);
            //setPortalId(response[0].siteId)
            
            setIsLoading(false);
        })

    }, [trackPageView])

    function changeSelection(item: IPortal)
    {
        setPortalId(item.siteId);
        setPortal(item);
        trackEvent({ 
            category: item.name,
            action: 'Portalauswahl' });
        console.log(item);
    }

    function goNext() {
        // console.log('GO-NEXT', product);
        checkoutStore.setSiteId(portal!!);
  
        history.push("/ihre-backlinks/produktauswahl");
    }

    function contentClickHandler(e: any)  {
    const targetLink = e.target.closest('a');
    if(!targetLink) return;
    
    if (targetLink.dataset.target !== "ext") {
        e.preventDefault();
        history.push(targetLink.pathname);
        
    } else {
        e.preventDefault();
        window.open(
            targetLink.href,
            '_blank'
            );
    }
    };

    
    if (isLoading) return <LoadingComponent content='Loading...' />

    return (
        <Fragment>
        <h1>Portalauswahl</h1>
        <p className="lead">Bitte wählen Sie das Portal aus, in dem Ihr Text veröffentlich werden soll und gehen Sie weiter zur Produktauswahl.</p>
        <hr />
        <div style={{textAlign: 'right'}}>
        <Button
            variant="success"
            onClick={() => goNext()}
            ><FontAwesomeIcon icon={faShoppingCart} style={{marginRight: '0.5em'}}/>Weiter zur Produktauswahl</Button>
        </div>
        <hr />
        <Table bordered striped>
            <thead>
                <tr>
                    <th style={{minWidth: '250px'}}>Portal</th>
                    <th>Beschreibung</th>
                </tr>
            </thead>
            <tbody>
                {portals.map((portal: IPortal, i) => (
                    <tr key={i}>
                        <td>
                        <Form.Group key={i} controlId={`bl${portal.id}`}>
                            <Form.Check type="checkbox"
                                disabled={!portal.isEnabled}
                                checked={portal.siteId === portalId}
                                onChange={() => changeSelection(portal)}
                                label={portal.name}
                                />
                                {!portal.isEnabled && 
                                    <span>In Vorbereitung</span>
                                }
                                {portal.isEnabled && 
                                    <span style={{color: 'green', fontWeight: 'bold'}}>aktiv</span>
                                }
                        </Form.Group>
                        </td>
                        <td>
                        <div onClick={contentClickHandler}
                        dangerouslySetInnerHTML={{
                            __html: portal.description != null ? portal.description : ''
                        }}></div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>

        <hr />
        <div style={{textAlign: 'right'}}>
        <Button
            variant="success"
            onClick={() => goNext()}
            ><FontAwesomeIcon icon={faShoppingCart} style={{marginRight: '0.5em'}}/>Weiter zur Produktauswahl</Button>
        </div>

        </Fragment>
    )
})