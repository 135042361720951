import { useMatomo } from '@datapunt/matomo-tracker-react';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getCookieConsentValue } from 'react-cookie-consent';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { IBlogPost } from '../../app/models/content/IBlogPost';
import AdvertsBlock from '../adverts/AdvertBlock';
import BacklinkProductBlock from '../blocks/BacklinkProductBlock';
import LastArticlesBlock from '../blocks/LastArticlesBlock';
import NewsletterSubscribe from '../home/NewsletterSubscribe';
import SocialShare from '../home/SocialShare';
import Bio from './Bio';
import Comments from './Comments';
import PostTags from './postTags';

export default observer(function ShowBlogPost () {

    const { enableLinkTracking, trackPageView } = useMatomo();

    enableLinkTracking();

    const history = useHistory();
    const [blogpost, setBlogpost] = useState<IBlogPost>();
    const [cookie, setCookie] = useState(getCookieConsentValue('kayomoCookieConsent'));
    const [isLoading, setIsLoading] = useState(true);
    

    const {slug} = useParams<{slug: string}>();

    useEffect(() => {     
        setIsLoading(true);   
        agent.Posts.loadPost(slug).then(response => {
            // console.log(response);
            setBlogpost(response);

            setIsLoading(false);

            trackPageView({
                documentTitle: blogpost?.title
            });
        })

        setCookie(getCookieConsentValue('kayomoCookieConsent'));
        // console.log(getCookieConsentValue('kayomoCookieConsent'));
    },[slug, trackPageView])

    function contentClickHandler(e: any)  {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        
        if (targetLink.dataset.target !== "ext") {
            e.preventDefault();
            history.push(targetLink.pathname);
            
        } else {
            e.preventDefault();
            window.open(
                targetLink.href,
                '_blank'
              );
        }
      };

    if (isLoading) return <LoadingComponent content='Loading...' />

    return (    
        <Fragment>    
        <Helmet>
                <title>{blogpost?.title + ' | ' + process.env.REACT_APP_PORTAL_NAME}</title>      
                <meta name="description" content={blogpost?.metaDescription} />
                <meta name="keywords" content={blogpost?.metaKeywords} />
                <link rel="canonical" href={window.location.href} />
        </Helmet>
       
        <Row>
        <Col md={9}>
        <h1>{blogpost?.title}</h1>
        <p style={{fontSize: 'small'}}>Geschrieben am {blogpost?.created} von {blogpost?.author}</p>
        <hr />

        <AdvertsBlock advertCount={1} advertType={5} />

        <div
        onClick={contentClickHandler}
        dangerouslySetInnerHTML={{
            __html: blogpost != null ? blogpost.content : ''
        }}></div>

        {blogpost?.postTags &&
            <PostTags postTags={blogpost?.postTags} />
        }

        {blogpost?.postsWithBio && blogpost.bio && 
                <Bio bio={blogpost.bio} />
        }

        {cookie && cookie === 'true' && 
        <Fragment>
            <hr />
            <SocialShare shareUrl={window.location.href} shareTitle={blogpost?.title!!} />
        </Fragment>
        }

        <hr />
        <Comments slug={slug}/>

        </Col>
        <Col md={3}>     
            <BacklinkProductBlock />
            <LastArticlesBlock articleCount={5} />
            <AdvertsBlock advertCount={1} advertType={6} />
            <NewsletterSubscribe />
        </Col>
        </Row>
        </Fragment>        
    )
})