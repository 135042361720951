import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/layout/App';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import {createBrowserHistory} from 'history';
import ScrollToTop from './app/layout/ScrollToTop';
import { store, StoreContext } from './app/stores/store';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

export const history = createBrowserHistory();

const instance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_BASE!!,
  siteId: 5,
  userId: '', // optional, default value: `undefined`.
  trackerUrl: `${process.env.REACT_APP_MATOMO_BASE}/matomo.php`, // optional, default value: `${urlBase}matomo.php`
  srcUrl: `${process.env.REACT_APP_MATOMO_BASE}/matomo.js`, // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: false,
    setRequestMethod: 'POST'
  }
})


ReactDOM.render(
  // <React.StrictMode>
  <MatomoProvider value={instance}>
  <StoreContext.Provider value={store}>
  <Router history={history}>
    <ScrollToTop />
      <App />
  </Router>
  </StoreContext.Provider>
  </MatomoProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();