import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import { ICustomerInput } from '../../app/models/forms/ICustomerInput';
import * as Yup from 'yup';
import { useStore } from '../../app/stores/store';
import agent from '../../app/api/agent';
import { ICountry } from '../../app/models/order/ICountry';
import { ICheckVatId } from '../../app/models/order/ICheckVatId';
import { ITaxCheck } from '../../app/models/order/ITaxInfo';

interface Props {
    stepAction: (step: number) => void;
}

export default observer(function CheckOutAddress({stepAction}: Props) {

    const { checkoutStore } = useStore();
    const { order } = checkoutStore;

    const [customer, setCustomer] = useState<ICustomerInput>(order.customer as ICustomerInput);
    const [countries, setCountries] = useState<ICountry[]>();
    const [vatvalid, setVatValid] = useState(false);

    useEffect(() => {        
        setCustomer(order.customer as ICustomerInput);
        setVatValid(order.customer.vatValid);
    }, [])

    useEffect(() => {        
        agent.Orders.loadCountries().then(response => {
            // console.log(response);
            setCountries(response);
        })
    }, [])

    function handleFormSubmit(input: ICustomerInput) {
        // console.log('Form abgesendet', input);

        // console.log('TAX-INFO-ABRUFEN');
        var taxCheck = {
            vatId: input.vatID,
            countryId: input.countryId
        } as ITaxCheck;

        agent.Orders.getTaxInfo(taxCheck).then(response => {
            //  console.log(response);
             checkoutStore.setTaxInfo(response);
             checkoutStore.calculateTotal();
        })

        // console.log('WEITER STEP 2');
        try {
            input.vatValid = vatvalid;
            setCustomer(input);
            order.customer = input;
            // console.log('CUSTOMER', order.customer);
            stepAction(2);
        } catch (error) {
            throw error;
        }
    }

    function checkVat(vat: ICheckVatId) {
        // console.log('CHECK-VAT:', vat);
        agent.Orders.checkVatId(vat).then(response => {
            // console.log(response);
            setVatValid(response);
            checkoutStore.setVatValid(response);
            // console.log(order.customer);
        })
    }

    const validationSchema = Yup.object({
        gender: Yup.number()
            .required(),
        foreName: Yup.string()        
            .required('Bitte geben Sie Ihren Vornamen ein')
            .min(3, 'Der Vorname muss mindestens 3 Zeichen lang sein')
            .max(30),
        lastName: Yup.string()        
            .required('Bitte geben Sie Ihren Nachnamen ein')
            .min(4, 'Der Nachname muss mindestens 4 Zeichen lang sein')
            .max(30),
        street: Yup.string()        
            .required('Bitte geben Sie Ihre Straße ein')
            .min(4, 'Die Straße muss mindestens 4 Zeichen lang sein')
            .max(50),
        postal: Yup.string()        
            .required('Bitte geben Sie Ihre Postleitzahl ein')
            .min(4, 'Die Postleitzahl muss mindestens 4 Zeichen lang sein')
            .max(8),
        city: Yup.string()        
            .required('Bitte geben Sie Ihren Ort ein')
            .max(40),
        email: Yup.string()
            .email('Bitte geben Sie eine gültige Email-Adresse ein')
            .required('Bitte geben Sie eine Email-Adresse ein')
            .max(50),
        email2: Yup.string()
            .email('Bitte geben Sie eine gültige Email-Adresse ein')
            .oneOf([Yup.ref('email'), null], 'Die Passworteingaben stimmen nicht überein')
            .required('Bitte geben Sie eine Email-Adresse ein'),
        company: Yup.string()
            .when('vatID',{
                is: (value: any) => !!value,
                then: Yup.string()
                .required('Bitte geben Sie einen Firmennamen an')
                .max(50)
            }),
        vatID: Yup.string()
    })

    return (
        <Fragment>
            <h2>Kundendaten</h2>
            <p>Dieses Angebot richtet sich ausschließlich an Unternehmen und gewerblich tätige Personen.</p>
            <hr />
            <Formik
            // enableReinitialize
            validationSchema={validationSchema}
            initialValues={customer}
            onSubmit={values => handleFormSubmit(values)}>
            {({handleSubmit,
            handleChange,
            handleBlur,
            values,
            isSubmitting,
            isValid,
            errors,}) => (
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md={2}>
                        <Form.Group controlId="gender">
                            <Form.Label>Anrede</Form.Label>
                            <Form.Control as="select" name="gender" onChange={handleChange} value={values.gender}>
                                <option value="0">Herr</option>
                                <option value="1">Frau</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                    <Form.Group controlId="foreName">
                        <Form.Label>Vorname</Form.Label>
                        <Form.Control type="text" name="foreName" placeholder="Ihr Vorname" onChange={handleChange} isInvalid={!!errors.foreName} value={values.foreName} />
                        <Form.Control.Feedback type="invalid">{errors.foreName}</Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group controlId="lastName">
                        <Form.Label>Nachname</Form.Label>
                        <Form.Control type="text" name="lastName" placeholder="Ihr Nachname" onChange={handleChange} isInvalid={!!errors.lastName} value={values.lastName} />
                        <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col>
                    <Form.Group controlId="company">
                        <Form.Label>Firmenname</Form.Label>
                        <Form.Control type="text" name="company" placeholder="Firmenname" onChange={handleChange} isInvalid={!!errors.company} value={values.company} />
                        <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col md={2}>
                    <Form.Group controlId="vatID">
                        <Form.Label>Umsatzsteuer-Id</Form.Label>
                        <Form.Control type="text" name="vatID" placeholder="Umsatzsteuer-ID" onChange={handleChange} isInvalid={!!errors.vatID} value={values.vatID} onBlur={() => checkVat({'vatId': values.vatID, 'countryId': values.countryId})} />
                        <Form.Control.Feedback type="invalid">{errors.vatID}</Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col md={2}>
                    <Form.Group controlId="vatStatus" style={{verticalAlign: 'bottom'}}>
                        {values.company && vatvalid && 
                            <span style={{color: 'green'}}>gültig</span>
                        }

                        {values.company && !vatvalid && 
                            <span style={{color: 'red'}}>ungültig</span>
                        }
                    </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col md={6}>
                    <Form.Group controlId="street">
                        <Form.Label>Straße</Form.Label>
                        <Form.Control type="text" name="street" placeholder="Straße" onChange={handleChange} isInvalid={!!errors.street} value={values.street} />
                        <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col md={2}>
                        <Form.Group controlId="postal">
                            <Form.Label>Postleitzahl</Form.Label>
                            <Form.Control type="text" name="postal" placeholder="Postleitzahl" onChange={handleChange} isInvalid={!!errors.postal} value={values.postal} />
                            <Form.Control.Feedback type="invalid">{errors.postal}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group controlId="city">
                        <Form.Label>Stadt</Form.Label>
                        <Form.Control type="text" name="city" placeholder="Stadt" onChange={handleChange} isInvalid={!!errors.city} value={values.city} />
                        <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col md={4}>
                    <Form.Group controlId="countryId">
                        <Form.Label>Land</Form.Label>
                        <Form.Control as="select" name="countryId" onChange={handleChange} onBlur={() => checkVat({'vatId': values.vatID, 'countryId': values.countryId})} value={values.countryId}>
                        {countries?.map((country: ICountry, i) => (
                            <option key={i} value={country.id}>{country.name}</option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    </Col>
                   
                </Form.Row>



                <Form.Row>
                    <Col>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" name="email" placeholder="Ihr Email" onChange={handleChange} isInvalid={!!errors.email} value={values.email} />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group controlId="email2">
                        <Form.Label>Email (Wiederholung)</Form.Label>
                        <Form.Control type="text" name="email2" placeholder="Email (Wiederholung)" onChange={handleChange} isInvalid={!!errors.email2} value={values.email2} />
                        <Form.Control.Feedback type="invalid">{errors.email2}</Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                </Form.Row>

                <hr />
                {values.company && !vatvalid && 
                   <Alert variant='danger'>
                   Bitte prüfen Sie Ihre Angaben zur Umsatzsteuer-Id und dem Land
                 </Alert>
                }

                <div style={{textAlign: 'right'}}>
                <Button
                    variant="success"
                    disabled={isSubmitting || !isValid || !vatvalid}
                    type="submit"
                    >Weiter</Button>
                </div>
            </Form>
            )}
            </Formik>
        </Fragment>
    )
})