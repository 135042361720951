import { IPortal } from "../portals/IPortal";
import { IOrderCustomer } from "./IOrderCustomer";
import { IOrderItem, IOrderTotal } from "./IOrderItem";
import { ITaxInfo } from "./ITaxInfo";

export class OrderObject {
    orderRef: string;
    acceptedTerms: boolean = false;
    acceptedBusiness: boolean = false;
    acceptedContent: boolean = false;
    customer: IOrderCustomer;
    basket: IOrderItem[] = [];
    total: IOrderTotal;
    paymentMethod: string;
    taxInfo: ITaxInfo;
    portal: IPortal;

    constructor() {
        this.orderRef = '';
        this.customer = {} as IOrderCustomer;
        this.customer.gender = 0;
        this.customer.foreName = '';
        this.customer.lastName = '';
        this.customer.email = '';    
        this.customer.email2 = '';          
        this.customer.company = '';
        this.customer.vatID = '';
        this.customer.vatValid = false;
        this.customer.street = '';
        this.customer.postal = '';
        this.customer.city = '';
        this.customer.countryId = 230;
        this.paymentMethod = 'moneyorder';

        this.total = {} as IOrderTotal;
        this.total.sum = 0;
        this.total.tax = 0;
        this.total.total = 0;

        this.taxInfo = {} as ITaxInfo;
        this.taxInfo.taxStandard = 0;
        this.taxInfo.taxReduced = 0;
        this.taxInfo.taxEP = 0

        this.portal = {} as IPortal;
    }
}