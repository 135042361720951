import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';

import {
    EmailShareButton,
    FacebookShareButton,
    // HatenaShareButton,
    // InstapaperShareButton,
    // LineShareButton,
    LinkedinShareButton,
    // LivejournalShareButton,
    // MailruShareButton,
    // OKShareButton,
    // PinterestShareButton,
    // PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    // TumblrShareButton,
    TwitterShareButton,
    // ViberShareButton,
    // VKShareButton,
    WhatsappShareButton,
    // WorkplaceShareButton
  } from "react-share";

  import {
    FacebookShareCount,
    // HatenaShareCount,
    // OKShareCount,
    // PinterestShareCount,
    // RedditShareCount,
    // TumblrShareCount,
    // VKShareCount
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    // FacebookMessengerIcon,
    // HatenaIcon,
    // InstapaperIcon,
    // LineIcon,
    LinkedinIcon,
    // LivejournalIcon,
    // MailruIcon,
    // OKIcon,
    // PinterestIcon,
    // PocketIcon,
    RedditIcon,
    TelegramIcon,
    // TumblrIcon,
    TwitterIcon,
    // ViberIcon,
    // VKIcon,
    // WeiboIcon,
    WhatsappIcon,
    // WorkplaceIcon
  } from "react-share";

interface Props {
    shareUrl: string;
    shareTitle: string;
}

export default function SocialShare({shareUrl, shareTitle}: Props) {

    return (        
        <Fragment >
            <Row>
                <Col>
                <FacebookShareButton
                    url={shareUrl}
                    quote={shareTitle}
                    className="Demo__some-network__share-button"
                >
                    <FacebookIcon size={32} />
                </FacebookShareButton>

                <div>
                    <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                    {count => count}
                    </FacebookShareCount>
                </div>
                </Col>
                
                <Col>
                <TwitterShareButton
                    url={shareUrl}
                    title={shareTitle}
                    className="Demo__some-network__share-button"
                >
                    <TwitterIcon size={32} />
                </TwitterShareButton>
                </Col>

                <Col>
                <TelegramShareButton
                    url={shareUrl}
                    title={shareTitle}
                    className="Demo__some-network__share-button"
                >
                    <TelegramIcon size={32} />
                </TelegramShareButton>
                </Col>

                <Col>
                <WhatsappShareButton
                    url={shareUrl}
                    title={shareTitle}
                    separator=":: "
                    className="Demo__some-network__share-button"
                >
                    <WhatsappIcon size={32} />
                </WhatsappShareButton>
                </Col>

                <Col>
                <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
                    <LinkedinIcon size={32} />
                </LinkedinShareButton>
                </Col>

                {/* <Col>
                <PinterestShareButton
                    url={String(window.location)}
                    media={`${String(window.location)}/${exampleImage}`}
                    className="Demo__some-network__share-button"
                >
                    <PinterestIcon size={32} />
                </PinterestShareButton>
                </Col> */}

                <Col>
                <RedditShareButton
                    url={shareUrl}
                    title={shareTitle}
                    windowWidth={660}
                    windowHeight={460}
                    className="Demo__some-network__share-button"
                >
                    <RedditIcon size={32} round />
                </RedditShareButton>
                </Col>

                <Col>
                <EmailShareButton
                    url={shareUrl}
                    subject={shareTitle}
                    body="body"
                    className="Demo__some-network__share-button"
                >
                    <EmailIcon size={32} />
                </EmailShareButton>
                </Col>

            </Row>
         
        </Fragment>
    )
}