import React, { Fragment, useEffect } from 'react';
import { useStore } from '../../app/stores/store';


export default function ThankYou () {

    const { checkoutStore } = useStore();

    useEffect(() => {        
        checkoutStore.resetOrderStore();
    }, [checkoutStore])

    return (
        <Fragment>
            <h1>Vielen Dank für Ihre Bestellung!</h1>
            <p>Sie erhalten innerhalb der nächsten Minuten eine Auftragsbestätigung und eine Rechnung per Mail zugesendet.</p>
            <p>Bitte folgenden Sie den Anweisungen in der Auftragsbestätigung, um uns die notwendigen Informationen zu Ihrem Auftrag zukommen zu lassen.</p>
            <p>Wir freuen uns auf die Zusammenarbeit!</p>
            <p>Danke und viele Grüße,<br />Ihr kayomo Team</p>
        </Fragment>
    )
}