import React, { Fragment, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { IBlogPostShort } from '../../app/models/content/IBlogPostShort';

interface Props {
    articleCount: number;
}

export default function LastBlogPosts ({articleCount}: Props) {

    const [blogposts, setBlogposts] = useState<IBlogPostShort[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        agent.Posts.lastPosts(articleCount).then(response => {
            // console.log(response);
            setBlogposts(response);
            setIsLoading(false);
        })
    }, [articleCount])

    if (isLoading) return <LoadingComponent content='Loading...' />
    
    return (
        <Fragment>
            <h3>Neueste Artikel</h3>
           
            {blogposts.map((post: IBlogPostShort, i) => (
                <Card key={i} style={{marginBottom: '1em'}}>
                    <Card.Body>
                        <Card.Title><Link to={`/artikel/${post.slug}`}>{post.title}</Link></Card.Title>
                        <hr />
                        <Card.Text>{post.abstract}</Card.Text>
                    </Card.Body>
                </Card>
            ))}

        
        </Fragment>
    )
}