import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import agent from '../../app/api/agent';
import { IOrderCreate } from '../../app/models/order/IOrderCreate';
import { IOrderItem } from '../../app/models/order/IOrderItem';
import { useStore } from '../../app/stores/store';

interface Props {
    stepAction: (step: number) => void;
}

export default observer(function CheckOutSummary({stepAction}: Props) {

    const { checkoutStore } = useStore();
    const { order } = checkoutStore;

    const [basket, SetBasket] = useState<IOrderItem[]>(order.basket);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {        
        SetBasket(order.basket);
        // console.log(basket);
    }, [basket, order.basket])

    function updateStep(step: number) {
        // checkoutStore.updateStep(step);
        stepAction(step);
    }

    function processOrder(step: number) {

        var order = {
            productId: basket[0].model,
            productName: basket[0].name,
            productPrice: basket[0].price,
            total: checkoutStore.order.total.total,
            tax: checkoutStore.order.total.tax,
            taxRate: checkoutStore.order.taxInfo.taxEP,
            customer: checkoutStore.order.customer,
            siteId: checkoutStore.order.portal.siteId
        } as IOrderCreate;

        try {
            setIsLoading(true);
            agent.Orders.createOrder(order).then(response => {
                // console.log(response);
                checkoutStore.setOrderRef(response.orderRef);
                // console.log(order);
                setIsLoading(false);
                stepAction(step);
            })
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    }

    return (
        <Fragment>
            <h2>Zusammenfassung Bestellung</h2>
            <p>Dieses Angebot richtet sich ausschließlich an Unternehmen und gewerblich tätige Personen.</p>
            <hr />

            <Table bordered>
            <thead style={{backgroundColor: 'lightblue'}}>
                <tr>
                <th>Position</th>
                <th style={{textAlign: 'right'}}>Netto (EUR)</th>
                <th style={{textAlign: 'right'}}>Gesamt (EUR)</th>
                </tr>
            </thead>
            <tbody>
            {basket.map((item, i) => ([
                <tr key={i}>
                    <td>
                        
                        <strong>{item.name}</strong><br /><span style={{fontSize: '0.8em'}}>
                        {/* {item.description} */}
                        <ul>
                            <li>{item.textCount} {item.textCount > 1 ? 'Texte á' : 'Text'} 500-1.500 Wörter</li>
                            <li>{item.linkCount} {item.linkCount > 1 ? 'Backlinks' : 'Backlink'} {item.textCount > 1 ? 'pro' : 'im'} Text</li>
                            {item.hasBio && 
                                <li>Unternehmensdarstellung</li>
                            }
                            {item.seoCheck && 
                                <li>SEO Überprüfung des Textinhalts</li>
                            }
                            {item.indexing && 
                                <li>Indexierung der veröffentlichten Webseite</li>
                            }
                            {/* {item.description &&
                                <li>{item.description}</li>
                            } */}
                        </ul>
                        </span>
                        <strong>Plattform: </strong>{order.portal.name}<br /><br />    
                    </td>
                    <td style={{textAlign: 'right'}}>
                        <NumberFormat value={item.price} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale />
                    </td>
                    <td style={{textAlign: 'right'}}>
                        <NumberFormat value={item.price} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale />
                    </td>
                </tr>

            ]))}

            <tr style={{fontWeight: 'bold'}} >
                <td colSpan={2} style={{textAlign: 'right'}}>Zwischensumme</td>
                <td style={{textAlign: 'right'}}>
                    <NumberFormat value={order.total.sum} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale />
                </td>
            </tr>

            <tr>
                <td colSpan={2} style={{textAlign: 'right'}}>zzgl. {order.taxInfo.taxEP}% MwSt.</td>
                <td style={{textAlign: 'right'}}>
                    <NumberFormat value={order.total.tax} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale />
                </td>
            </tr>

            <tr style={{fontWeight: 'bold'}} >
                <td colSpan={2} style={{textAlign: 'right'}}>Gesamt</td>
                <td style={{textAlign: 'right'}}>
                    <NumberFormat value={order.total.total} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale />
                </td>
            </tr>

            </tbody>
            </Table>
            <hr />
            <Button variant="secondary" onClick={() => updateStep(2)}>Zurück</Button>
            <Button variant="success" style={{float: 'right'}} disabled={isLoading} onClick={() => processOrder(4)}>
            {isLoading && 
                <Spinner style={{marginRight: '10px'}}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            }
                Zahlungspflichtig bestellen
            </Button>
        </Fragment>
    )
})