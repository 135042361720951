import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function NavBar () {

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" >
            <Navbar.Brand as={Link} to="/" style={{color: 'orange'}}><FontAwesomeIcon icon={faLink} style={{marginRight: '0.1em'}}/>{process.env.REACT_APP_PORTAL_NAME}</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                <Nav.Link eventKey="2" as={Link} to='/artikel'>Artikel</Nav.Link>
                <Nav.Link eventKey="3" as={Link} to='/erfolgreich-im-internet'>Erfolgreich im Internet</Nav.Link>
                <Nav.Link eventKey="4" as={Link} to='/ihre-backlinks'>Ihre Backlinks</Nav.Link>
                <Nav.Link eventKey="5" as={Link} to='/ihre-werbung'>Ihre Werbung</Nav.Link>
                </Nav>
                {/* <Nav>
                <ButtonGroup>
                <Button className="mr-2" variant='primary'><FontAwesomeIcon icon={faAd} style={{marginRight: '0.5em'}}/>Ihre Werbung</Button>
                <Button as={Link} to='/ihre-backlinks' variant="success"><FontAwesomeIcon icon={faLink} style={{marginRight: '0.5em'}}/>Ihre Backlinks</Button>
                </ButtonGroup>
                </Nav> */}
            </Navbar.Collapse>
        </Navbar>
    )
}