import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { IComment } from '../../app/models/content/IComment';

interface Props {
    slug: string;
}

export default observer(function Comments ({slug}: Props) {

    const [comments, setComments] = useState<IComment[]>();
    const [isLoading, setIsLoading] = useState(true);

    let imagePath = '/assets/users/';

    useEffect(() => {    
        setIsLoading(true);    
        agent.Posts.loadComments(slug).then(response => {
            // console.log(response);
            setComments(response);
            setIsLoading(false);
        })
    }, [slug])

    if (isLoading) return <LoadingComponent content='Loading...' />
    
    return (
        <Fragment>
            <h4>Kommentare</h4>
            <hr />
            {comments?.map((comment, i) => [
                <Fragment key={i}>
                <Row>
                <Col md={1}>
                  <Image src={imagePath + comment.imageId + '.jpg'} rounded />
                </Col>
                <Col style={{fontSize: 'small'}}>
                    <strong>{comment.userName}</strong><br />
                    {comment.text}
                </Col>                
                </Row>
                <Row>
                   <Col md={6}>
                   <hr />
                   </Col>
                </Row>
                </Fragment>
            ])}
        </Fragment>
    )
})