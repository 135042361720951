import { useMatomo } from '@datapunt/matomo-tracker-react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import agent from '../../app/api/agent';
import { IOrderItem } from '../../app/models/order/IOrderItem';
import { useStore } from '../../app/stores/store';

export default observer(function BacklinkProductSelection() {

    const { enableLinkTracking, trackPageView, trackEvent } = useMatomo();
    enableLinkTracking();

    const history = useHistory();
    const { checkoutStore } = useStore();
    const [productModel, setProductModel] = useState(0);
    const [products, setProducts] = useState<IOrderItem[]>([]);
    const [product, setProduct] = useState<IOrderItem>();

    useEffect(() => {        
        trackPageView({
            documentTitle: 'Backlink - Produktauswahl'
        });

        agent.Products.loadProducts(0, checkoutStore.order.portal.siteId).then(response => {
            // console.log(response);
            setProducts(response);
            setProductModel(response[0].model);
            setProduct(response[0]);
        })
    }, [trackPageView])

    function changeSelection(item: IOrderItem)
    {
        setProductModel(item.model);
        setProduct(item);
        trackEvent({ 
            category: item.name,
            action: 'Produktauswahl' });
        // console.log(item);
    }

    function goNext() {
        // console.log('GO-NEXT', product);
        checkoutStore.setOrderItem(product!!);
  
        history.push("/ihre-backlinks/bestellung");
      }

    return (
        <Fragment>
        <h1>Produktauswahl</h1>
        <p className="lead">Bitte wählen Sie das gewünschte Backlink-Produkt aus und gehen Sie dann weiter zum Bestellformular.</p>
        <hr />

        <Table bordered>
            <thead style={{backgroundColor: 'lightblue'}}>
                <tr>
                <th>Produkt</th>
                <th style={{textAlign: 'right'}}>Preis (netto)</th>
                <th >Beschreibung</th>
                </tr>
            </thead>
            <tbody>

                {products.map((product: IOrderItem, i) => (
                    <tr key={i}>
                    <td>
                        <Form.Group key={i} controlId={`bl${product.model}`}>
                            <Form.Check type="checkbox"
                                checked={product.model === productModel}
                                onChange={() => changeSelection(product)}
                                label={product.name} />
                        </Form.Group>
                    </td>
                    <td style={{textAlign: 'right'}}>
                        <NumberFormat value={product.price} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale /> EUR
                    </td>
                    <td>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: product.description != null ? product.description : ''
                        }}></div>
                        <ul>
                            <li>{product.textCount} {product.textCount > 1 ? 'Texte á' : 'Text'} 500-1.500 Wörter</li>
                            <li>{product.linkCount} {product.linkCount > 1 ? 'Backlinks' : 'Backlink'} {product.textCount > 1 ? 'pro' : 'im'} Text</li>
                            {product.hasBio && 
                                <li>Unternehmensdarstellung</li>
                            }
                            {product.seoCheck && 
                                <li>SEO Überprüfung des Textinhalts</li>
                            }
                            {product.indexing && 
                                <li>Indexierung der veröffentlichten Webseite</li>
                            }
                           </ul>
                    </td>
                </tr>
                ))}

            </tbody>
        </Table>

        <hr />
        <div style={{textAlign: 'right'}}>
        <Button
            variant="success"
            onClick={() => goNext()}
            ><FontAwesomeIcon icon={faShoppingCart} style={{marginRight: '0.5em'}}/>Weiter zur Bestellung</Button>
        </div>
      
        </Fragment>
    )
})