import { makeAutoObservable, reaction } from "mobx";
import agent from "../api/agent";
import { IBlogPost } from "../models/content/IBlogPost";
import { IBlogPostShort } from "../models/content/IBlogPostShort";
import { IPagination, PagingParams } from "../models/pagination";

export default class BlogStore {
    posts: IBlogPostShort[] = [];
    post: IBlogPost | null = null;

    loading = false;
    loadingInitial = false;

    pagination: IPagination | undefined | null = null;
    pagingParams = new PagingParams();

    predicate = new Map();

    searchParamSelectedValue = '';
    searchTagSelectedValue = '';

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.predicate.keys(),
            () => {
                this.pagingParams = new PagingParams();
                this.loadPosts();
                this.predicate.forEach((value, key) => {
                    if (key === 'searchParam') this.searchParamSelectedValue = value;
                    if (key === 'searchTag') this.searchTagSelectedValue = value;
                })
            }
        )
    }

    get searchParamValue() {
        return this.searchParamSelectedValue;
    }

    get searchTagValue() {
        return this.searchTagSelectedValue;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPredicate = (predicate: string, value: string) => {
        const resetPredicate = () => {
            this.predicate.forEach((value, key) => {
                if (key === predicate) this.predicate.delete(key);
            })
        }

        resetPredicate();
        this.predicate.set(predicate, value);
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());     
        this.predicate.forEach((value, key) => {
            if (key === 'searchParam' && value !== '') {
                params.append(key, value);
            }

            if (key === 'searchTag' && value !== '') {
                params.append(key, value);
            }
        })   
        return params;
    }

    loadPosts = async () => {
        this.setLoadingInitial(true);
        try {
            const result = await agent.Posts.listPosts(this.axiosParams);
                this.setPosts(result.data);
                this.setPagination(result.pagination)
                this.setLoadingInitial(false);
        } catch (error) {
            // console.log(error);
            this.setLoadingInitial(false);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setPagination = (pagination: IPagination) => {
        this.pagination = pagination;
    }



    setPosts = (posts: IBlogPostShort[]) => {
        this.posts = posts;
    }
}