import React, { Fragment } from 'react';
import { Button, Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../app/stores/store';

interface Props {
    stepAction: (step: number) => void;
}

export default function MoneyOrderPayment ({stepAction}: Props) {

    const history = useHistory();
    const { checkoutStore } = useStore();
    const { order } = checkoutStore;

    // function btnAction(step: number) {
    //     stepAction(step);
    // }

    return (
        <Fragment>
        <p>Bitte überweisen Sie den Rechnungsbetrag in Höhe von</p>
        <p style={{textAlign: 'center'}}><strong><NumberFormat value={order.total.total} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale /> EUR</strong></p>
        <p>innerhalb von 7 Tagen auf das folgende Konto:</p>

        <Table>
            <tbody>
            <tr>
                <td>
                    Kontoinhaber     
                </td>
                <td>
                    kayomo GmbH
                </td>
            </tr>
            <tr>
                <td>
                    IBAN     
                </td>
                <td>
                    DE41100700240095502100
                </td>
            </tr>
            </tbody>
        </Table>
      
        <Button style={{float: 'right'}} variant="success" onClick={() => history.push("/bestellung/vielen-dank")} >Bestellung abschließen</Button>
        </Fragment>
    )
}