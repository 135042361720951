import React, { Fragment } from 'react';
import { useState } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { OrderObject } from '../../app/models/order/OrderObject';
import { IPayPalInfo } from '../../app/models/payment/IPayPalInfo';
import { useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

interface Props {
    orderData: OrderObject;
    processAction: (running: boolean) => void;
}

export default function PayPalPayment({orderData, processAction}: Props) {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [paypalError, setPayPalError] = useState(false);

  
    return (
    <Fragment>
        {isLoading && 
            <LoadingComponent />
        }
    
       
        <PayPalButton
            createOrder={(data: any, actions: any) => {
                // setIsLoading(true)
                processAction(true);
                
                return actions.order.create({
                purchase_units: [
                    {
                        description: orderData.basket[0].name,
                        amount: {
                            currency_code: "EUR",
                            value: orderData.total.total,
                        },
                    },
                ],                            
                });
            }}

            onApprove={(data: any, actions: any) => {
                // Capture the funds from the transaction
                setIsLoading(true)
                processAction(true);
                return actions.order.capture().then(function(details: any) {
      
                console.log('APPROVE_DATA', data);
                console.log('APPROVE_ACTIONS', data);
                const transactionId = details.purchase_units[0].payments.captures[0].id;
                console.log('DETAILS', transactionId);
                // OPTIONAL: Call your server to save the transaction
                var paypalData = {
                    // orderId: data.orderID,
                    orderId: transactionId ? transactionId : data.orderID,
                    orderRef: orderData.orderRef                    
                } as IPayPalInfo;

                console.log('PAYPAL_DATA', paypalData);

                try {
                    agent.Orders.paypalCompleted(paypalData).then(response => {
                        console.log(response);
                        setIsLoading(false);
                        processAction(false);
                        history.push("/bestellung/vielen-dank");
                    })
                } catch (error) {
                    setIsLoading(false);
                    processAction(false);
                    throw error;
                }
                });
              }}                
              
            onError={(err: object) => {
                console.log(err);
                setPayPalError(true);
            }}

            // Added via extension
            onCancel={() => {
                console.log('PAYPAL CANCEL');
                processAction(false);
            }}

            options={{
                clientId: process.env.REACT_APP_PAYPAL_ID,
                currency: 'EUR',                            
            }}     
            
            // options={{
            //     clientId: 'AT0bCMqsGR4APsHDeD40CfFufFCXTW-dE5PB3qKcgSPwG6W-IPeueWGjDx7LzBZcJzJS3C1lGMlahIGH',
            //     currency: 'EUR',                            
            // }}   

            onButtonReady={() => setIsLoading(false)}
        />

        {paypalError && 
            <Alert variant="danger">
            <Alert.Heading>PayPal Fehler</Alert.Heading>
            <p>
              Bei der Verarbeitung ist auf Seiten von PayPal ein Fehler aufgetreten.
            </p>
          </Alert>
        }
        </Fragment>
    )
}