import { useMatomo } from '@datapunt/matomo-tracker-react';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { useStore } from '../../app/stores/store';
import CheckOutAddress from './CheckOutAddress';
import CheckOutInformation from './CheckOutInformation';
import CheckOutPayment from './CheckOutPayment';
import CheckOutProgress from './CheckOutProgress';
import CheckOutSummary from './CheckOutSummary';
import { history } from '../..';

export default observer(function CheckOut() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();
    
    const { checkoutStore } = useStore();
    const { order } = checkoutStore;

    const [step, setStep] = useState(checkoutStore.step);

    useEffect(() => {        
        setStep(checkoutStore.step);
        // console.log('STEP', step);

        trackPageView({
            documentTitle: `Checkout - Step ${step}`
        });

    }, [checkoutStore, step, trackPageView])

    function handleStep(step: number) {
        // console.log('ORDER: ', backlinkOrderStore)
        // console.log('STEP ACTION: ', step);
        checkoutStore.updateStep(step);
        setStep(step);
    }

    return (
        <Fragment>
        <h1>Bestellung durchführen</h1>
        <p className="lead">Wir begleiten Sie durch den Bestellvorgang.</p>

        {step !== 5 && 
            <CheckOutProgress actualStep={step} />
        }

        {step === 1 &&
            <CheckOutAddress stepAction={handleStep} />
        }

        {step === 2 &&
            <CheckOutInformation stepAction={handleStep} />
        }

        {step === 3 &&
            <CheckOutSummary stepAction={handleStep} />
        }

        {step === 4 && 
            <CheckOutPayment stepAction={handleStep} />
        }

        { order.basket.length === 0 && 
            history.push("/ihre-backlinks/produktauswahl")
        }

        </Fragment>
    )
})