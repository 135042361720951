import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import agent from '../../app/api/agent';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { IPage } from '../../app/models/content/IPage';
import { useStore } from '../../app/stores/store';

interface Props {
    stepAction: (step: number) => void;
}

export default observer(function CheckOutInformation({stepAction}: Props) {

    const { checkoutStore } = useStore();

    const { order } = checkoutStore;

    const [gwCheck, setGwCheck] = useState(false);
    const [agCheck, setAgCheck] = useState(false);
    const [contentCheck, setContentCheck] = useState(false);
    const [btnCheck, setBtnCheck] = useState(false);
    const [show, setShow] = useState(false);
    const [page, setPage] = useState<IPage>();
    const [contentDescription, setContentDescription] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {        
        setIsLoading(true);
        agent.Orders.getContentDescription(order.portal.siteId).then(response => {
            // console.log(response);
            setContentDescription(response);
            setIsLoading(false);
        })
    }, [])
    
    useEffect(() => {        
        setGwCheck(order.acceptedBusiness);
        setAgCheck(order.acceptedTerms);
        setContentCheck(order.acceptedContent);
        setBtnCheck(agCheck && gwCheck && contentCheck);

    }, [gwCheck, agCheck, contentCheck, btnCheck, order.acceptedBusiness, order.acceptedTerms, order.acceptedContent])

    function changeGwCheck()
    {
        order.acceptedBusiness = !order.acceptedBusiness;
        setGwCheck(order.acceptedBusiness);
    }

    function changeAgCheck()
    {
        order.acceptedTerms = !order.acceptedTerms;
        setAgCheck(order.acceptedTerms);
    }

    function changeContentCheck()
    {
        order.acceptedContent = !order.acceptedContent;
        setContentCheck(order.acceptedContent);
    }

    function updateStep(step: number) {
        // checkoutStore.updateStep(step);
        stepAction(step);
    }

    function showModal() {
        agent.Pages.loadPage('agb').then(response => {
            setPage(response);
            setShow(true);
        })
    }

    if (isLoading) return <LoadingComponent content='Loading...' />

    return (
        <Fragment>
            <h2>Rechtliche Informationen</h2>
            <p>Dieses Angebot richtet sich ausschließlich an Unternehmen und gewerblich tätige Personen.</p>
            <hr />
            <p>Inhaltliche Textvorgaben für dieses Portal:</p>
            <div
                    dangerouslySetInnerHTML={{
                        __html: contentDescription
                    }}></div>
            <Form>
            <Form.Group controlId="contentCheck">
                <Form.Check type="checkbox"
                    checked={contentCheck}
                    onChange={() => changeContentCheck()}
                    label="Ich akzeptiere die inhaltlichen Vorgaben für Texte" />
            </Form.Group>

            <Form.Group controlId="gwCheck">
                <Form.Check type="checkbox"
                    checked={gwCheck}
                    onChange={() => changeGwCheck()}
                    label="Ich bestätige, dass die Bestellung im Rahmen meiner gewerblicher Tätigkeit durchgeführt wird" />
            </Form.Group>
            
            <Form.Group controlId="agbCheck2">
            <Form.Check type="checkbox"
                id="test"
                >
                <Form.Check.Input type="checkbox" checked={agCheck} onChange={() => changeAgCheck()}/>
                <Form.Check.Label>Ich akzeptiere die <Button size="sm" variant='info' onClick={() => showModal()}>Allgemeinen Geschäftsbedingungen und Kundeninformationen</Button></Form.Check.Label>
                <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback>
            </Form.Check>
            </Form.Group>

            </Form>

            <hr />
            <Button variant="secondary" onClick={() => updateStep(1)}>Zurück</Button>
            <Button variant="success"
                disabled={!btnCheck}
                style={{float: 'right'}}
                onClick={() => updateStep(3)}>Weiter</Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                scrollable
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                {page?.title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div
                    dangerouslySetInnerHTML={{
                        __html: page != null ? page.content : ''
                    }}></div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
})